import { Helmet } from 'react-helmet';
import { siteConfig } from '../config.js';
import SectionA from '../components/SectionA.js';
import Header from '../components/Header.js';
import SectionC from '../components/SectionC.js';
import SectionB from '../components/SectionB.js';
import SectionD from '../components/SectionD.js';
import SectionE from '../components/SectionE.js';
import Footer from '../components/Footer.js';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>{siteConfig.title}</title>
        <meta name="description" content={siteConfig.metaDescription} />
      </Helmet>

      <Header config={siteConfig} />
      <div id="home"><SectionA /></div>
      <div id="services"><SectionB /></div>
      <div id="about"><SectionC /></div>
      <div id="location"><SectionD /></div>
      <div id="contact"><SectionE /></div>
      <Footer />
    </>
  );
}
