import { useState } from 'react';
import { Dialog, Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Header = ({ config }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setMobileMenuOpen(false);
    }
  };

  return (
    <header className="bg-white sticky top-0 z-50">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        {/* Logo Section */}
        <div className="flex lg:flex-1">
          <a onClick={() => scrollToSection('home')} className="-m-1.5 p-1.5 cursor-pointer">
            <span className="sr-only">{config.title}</span>
            {config.logo.src && <img className="h-8 w-auto" src={config.logo.src} alt={config.logo.alt} />}
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="flex lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(true)}>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Desktop Navigation */}
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          {config.navigation.map((link) => (
            <button key={link.name} onClick={() => scrollToSection(link.sectionId)} className="text-sm font-semibold text-gray-900 hover:text-gray-600 transition-colors">
              {link.name}
            </button>
          ))}
        </Popover.Group>

        {/* Desktop Contact Button */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button onClick={() => scrollToSection('contact')} className="text-sm font-semibold text-gray-900 hover:text-gray-600 transition-colors">
            Contact <span aria-hidden="true">&rarr;</span>
          </button>
        </div>
      </nav>

      {/* Mobile Menu Dialog */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full bg-white p-6 sm:max-w-sm">
          <div className="flex items-center justify-between">
            <button onClick={() => scrollToSection('home')} className="-m-1.5 p-1.5">
              {config.logo.src && <img className="h-8 w-auto" src={config.logo.src} alt={config.logo.alt} />}
            </button>
            <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6">
            {config.navigation.map((link) => (
              <button key={link.name} onClick={() => scrollToSection(link.sectionId)} className="block text-gray-900 hover:bg-gray-50 w-full text-left px-3 py-2 rounded-lg">
                {link.name}
              </button>
            ))}
            <button onClick={() => scrollToSection('contact')} className="block text-gray-900 hover:bg-gray-50 w-full text-left px-3 py-2 rounded-lg">
              Contact
            </button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
