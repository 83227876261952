import React, { useState } from 'react';
import ImageModal from './ImageModal';
import { siteConfig } from '../config';

export default function SectionA() {
  const { businessDetails, features, images, theme } = siteConfig;
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div data-aos="fade-left">
      <div className={`overflow-hidden bg-white py-12 sm:py-36`}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className={`text-base font-semibold leading-7 text-${theme.primaryColor}`}>
                  {businessDetails.name}
                </h2>
                <p className={`mt-2 text-3xl font-bold tracking-tight text-${theme.secondaryColor} sm:text-4xl`}>
                  {businessDetails.headline}
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  We are located at{' '}
                  <span className={theme.accentColor}>{businessDetails.address}</span>. {businessDetails.description}
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => {
                    return (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
  
                        </dt>{' '}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    );
                  })}
                </dl>
              </div>
            </div>
            <img
              src={images.business.src}
              alt={images.business.alt}
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={images.business.width}
              height={images.business.height}
              onClick={() => setModalOpen(true)}
            />
            <ImageModal 
              isOpen={isModalOpen} 
              onClose={() => setModalOpen(false)} 
              imgSrc={images.business.src}
              width={images.business.width}
              height={images.business.height}
              alt={images.business.alt}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
