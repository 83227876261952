import React from 'react';
import Home from './page/Home';

export default function App() {
  return (
    <>
      <Home />
    </>
  );
}
