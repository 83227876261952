import React, { useState } from 'react';
import ImageModal from './ImageModal';
import { siteConfig } from '../config';

export default function SectionB() {
  const { services } = siteConfig;
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageModal = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  return (
    <div data-aos="fade-down">
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 pb-6 border-b border-gray-200">
              {services.sectionTitle}
            </h1>
            <hr />
            <p className="text-base pt-5 text-gray-500">
              {services.description}
            </p>

            {/* Main Service Callouts */}
            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {services.callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      onClick={() => openImageModal(callout.imageSrc)}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    {callout.name}
                  </h3>
                  <p className="text-base font-semibold text-gray-900">
                    {callout.description}
                  </p>
                </div>
              ))}
            </div>
            
          </div>
        </div>

        {/* Image Modal */}
        {selectedImage && (
          <ImageModal 
            isOpen={!!selectedImage}
            onClose={closeImageModal}
            imgSrc={selectedImage}
            width={2432}
            height={1442}
            alt={selectedImage}
          />
        )}
      </div>
    </div>
  );
}
