import React from 'react';
import { siteConfig } from '../config';

export default function Footer() {
  const { businessDetails, footer } = siteConfig;
  
  return (
    <footer className="py-4" style={{ backgroundColor: '#FF851A' }}>
      <div className="container mx-auto flex text-black items-center justify-between text-white px-4">
        
        <p className="text-sm md:text-base">
          © 2024 {businessDetails.name}
        </p>
        
        <div className="flex items-center space-x-2">
          <span className="text-sm">Powered by: </span>
          <img src={footer.bizSolutionsLogo} alt="BizSolutions Logo" className="h-6 w-auto" />
          <span className="text-sm">BizSolutions LLC</span>
        </div>
        
      </div>
    </footer>
  );
}