// config.js

const bizIcon = "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg";
const imageOne = "https://www.elitehomeexteriorsnw.com/wp-content/uploads/2022/12/how-to-modernize-the-exterior-of-your-home-700x400.jpg";
const imageTwo = "https://www.elitehomeexteriorsnw.com/wp-content/uploads/2022/12/how-do-i-modernize-the-exterior-of-my-house-700x400.jpg";
const imageThree = "https://s1.elespanol.com/2024/06/21/invertia/observatorios/vivienda/864673701_244208788_1706x960.jpg";
const imageFour = "https://upload.wikimedia.org/wikipedia/commons/1/15/Gfp-southern-wisconsin-empty-farm-to-the-horizon.jpg";
const imageFive = "https://metroplexbestroofing.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-29-at-4.47.16-PM.jpeg";

const socialMediaLink = 'https://www.thomasariazsellscahomes.com/';

export const siteConfig = {
  title: "Thomas Ariaz | Tulare CA Realtor",
  metaDescription: "Thomas Ariaz | Tulare CA Realtor",
  theme: {
    primaryColor: "blue-500",
    secondaryColor: "gray-200",
    accentColor: "text-blue-500"
  },
  logo: {
    src: bizIcon,
    alt: "Thomas Ariaz | Tulare CA Realtor",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Thomas Ariaz | Tulare CA Realtor",
    headline: "Your Trusted Real Estate Partner in Tulare.",
    address: "1417 North Main Street, Tulare, CA 93275",
    description: "Helping you find the perfect property to call home, with expert guidance and a personal touch."
  },  
  images: {
    business: {
      src: imageOne,
      alt: "Premium Real Estate Agent",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description: 
        "With in-depth knowledge of local communities, market trends, and real estate conditions, I am committed to delivering expert guidance and making your real estate journey seamless and rewarding."
    },
    {
      description: 
        "Whether you're buying your dream home or selling your current property, I provide personalized advice and support every step of the way. Contact me today to discuss your unique real estate goals."
    },
    {
      description: 
        "Your community matters. As a local expert, I’ll help you find the perfect neighborhood that matches your lifestyle, offering insights on schools, amenities, activities, and market trends."
    }
  ],  
  services: {
    sectionTitle: "Our Excellent Real Estate Properties",
    callouts: [
      {
        name: 'Single-Family Homes',
        description: 'Discover charming single-family homes designed for comfort, privacy, and family living.',
        imageSrc: imageTwo,
        imageAlt: 'A beautiful single-family home with a lush green lawn',
      },
      {
        name: 'Condos & Townhouses',
        description: 'Explore modern condos and townhouses offering convenience, style, and low-maintenance living.',
        imageSrc: imageThree,
        imageAlt: 'A sleek townhouse in a vibrant community',
      },
      {
        name: 'Lots & Acreage',
        description: 'Find the perfect lot or acreage to build your dream home or expand your investment portfolio.',
        imageSrc: imageFour,
        imageAlt: 'An expansive piece of land ready for development',
      }
    ],
    otherServices: [

    ]
  },  
  about: {
    sectionTitle: "About Us",
    description: "With deep expertise in local communities, market trends, and the complexities of buying and selling real estate, I am committed to delivering exceptional service at every step. Whether you're searching for your dream home or planning to sell your property, I provide personalized solutions tailored to your needs. As a trusted local realtor, I help you navigate the market with confidence, ensuring you find the perfect home at the right price, complete with all the neighborhood features that matter most to you.",
    image: {
      src: imageFive,
      alt: "Thomas Ariaz | Tulare CA Realtor",
      width: 2432,
      height: 1442
    }
  },  
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "1417 North Main Street, Tulare, CA 93275, USA",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.4614932042143!2d-119.3442802!3d36.2159471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80952d4db3822e5f%3A0x4c1d40c5e4a5b3a1!2s1417%20N%20Main%20St%2C%20Tulare%2C%20CA%2093275%2C%20USA!5e0!3m2!1sen!2sph!4v1710407450828!5m2!1sen!2sph",
    subCategories: [
      { name: 'Apple Maps', url: 'https://maps.apple.com/?address=1417%20North%20Main%20Street,%20Tulare,%20CA%2093275&ll=36.2159471,-119.3442802&q=1417%20North%20Main%20Street,%20Tulare,%20CA%2093275' },
      { name: 'Bing Maps', url: 'https://www.bing.com/maps?q=1417+North+Main+Street,+Tulare,+CA+93275&form=HDRSC6&sp=1&pq=1417+north+main+street+tulare+ca+93275&sc=0-0&qs=n&sk=&cvid=EDFC0FE5A0E74E8DB130342A842AA10C' },
      { name: 'Google Earth', url: 'https://earth.google.com/web/search/1417+North+Main+Street,+Tulare,+CA+93275,+USA/@36.2159471,-119.3442802,15.26523397a,814.57589625d,35y,0h,0t,0r' },
      { name: 'Google Maps', url: 'https://maps.app.goo.gl/ZxyTt7c6YPoL5xFA6' }
    ]
  },
  contact: {
    sectionTitle: "Contact us!",
    description: "We look forward to providing you with a real estate experience that exceeds your expectations.",
    socialLinks: {
      facebook: socialMediaLink,
      instagram: socialMediaLink,
      twitter: socialMediaLink,
      youtube: socialMediaLink,
      tiktok: socialMediaLink
    },
    contactNumbers: ["+1559-697-1399"],
    logo: bizIcon,
    emailRecipient: "Tomz715@comcast.net"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
