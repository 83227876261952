import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { siteConfig } from '../config';

export default function SectionD() {
  const { location } = siteConfig;
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const openGoogleDirections = () => {
    const destination = encodeURIComponent(location.destinationAddress);
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div className="bg-white" data-aos="fade-up">
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form className="mt-4 border-t border-gray-200">
                  <ul className="px-2 py-3 font-medium text-gray-900">
                    {location.subCategories.map((category) => (
                      <li key={category.name}>
                        <a href={category.url} className="block px-2 py-3 text-left w-full" target="_blank" rel="noopener noreferrer">
                          {category.name}
                        </a>
                      </li>
                    ))}
                    <li>
                      <button onClick={openGoogleDirections} className="block px-2 py-3 text-left w-full">Google Directions</button>
                    </li>
                  </ul>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Main Content */}
      <div className='bg-gray-100'> 
        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 pb-6 pt-24 border-b border-gray-200">
            {location.sectionTitle}
          </h1>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Desktop Filters */}
              <form className="hidden lg:block">
                <ul className="px-2 py-3 font-medium text-gray-900">
                  {location.subCategories.map((category) => (
                    <li key={category.name}>
                      <a href={category.url} className="block px-2 py-3 text-left w-full" target="_blank" rel="noopener noreferrer">
                        {category.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <button onClick={openGoogleDirections} className="block px-2 py-3 text-left w-full">Google Directions</button>
                  </li>
                </ul>
              </form>

              {/* Google Maps Embed */}
              <div className="lg:col-span-3">
                <iframe
                  src={location.googleMapsEmbedUrl}
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="M. Ignacio Warehouse Location"
                ></iframe>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
